import { Component, Prop } from 'vue-property-decorator'
import BaseSignUpMixin from '../mixins/baseSignUpMixin'

@Component
export default class LoginForm extends BaseSignUpMixin {
  @Prop({ type: Boolean }) readonly hideResetPasswordLink!: boolean

  mounted () {
    this.mode = this.$route.meta.requiresAuth ? 'in-app-login' : ''
  }
}
