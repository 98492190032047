import { Component } from 'vue-property-decorator'
import BaseSignUpMixin from '../mixins/baseSignUpMixin'
import { AUTH_CONSENT_FAILED, AUTH_SUCCESS, AuthServiceError, AuthTermsConsentData } from 'booksprout-app'
import { Getter } from 'vuex-class'
import SocialLoginButtons from '../components/LoginButtons.vue'
import LoginForm from '../components/LoginForm.vue'

@Component({
  components: {
    SocialLoginButtons,
    LoginForm
  }
})
export default class LoginComponent extends BaseSignUpMixin {
  @Getter('termsConsentFailed') termsConsentFailed!: boolean
  @Getter('termsConsentData') termsConsentData!: AuthTermsConsentData

  public onConsentReinstateCancel () {
    void this.$store.dispatch(AUTH_CONSENT_FAILED, null)
  }

  public onConsentReinstateOk () {
    return this.$store.dispatch(AUTH_SUCCESS, {
      loginResult: {
        ...this.termsConsentData.loginResult,
        oAuthRegistered: false,
        termsConsentFailed: false,
        termsConsentNeeded: false
      }
    }).then((loginResult) => {
      return this.userService.reinstateContent().then(() => {
        this.onSuccessfulLogin(loginResult)
      }).catch((e: AuthServiceError) => {
        this.showError(e.message)
      })
    }).catch((e: AuthServiceError) => {
      this.showError(e.message)
    })
  }

  public created () {
    this.setPageMeta('login')

    if (this.isAuthenticated) {
      void this.$router.push(this.linkService.homeUrl)
    }
  }
}
